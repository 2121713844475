import React, { ReactNode } from 'react';
import {
  FormControl, MenuItem, SelectChangeEvent, SelectProps, Stack, Typography,
} from '@mui/material';
import MuiSelect from '@mui/material/Select';
import { KeyboardArrowDown } from '@mui/icons-material';
import { FieldError } from 'react-hook-form';

export type SelectCustomProps = {
  items: object[];
  fullWidth?: boolean;
  labelName?: string;
  valueName?: string;
  renderValue?: (value: any) => React.ReactNode;
  onChange: (event: SelectChangeEvent<any>, child: ReactNode) => void;
  textColor?: 'primary';
  onItemClick?: (item: any) => void;
  error?: FieldError;
} & Omit<SelectProps, 'onChange' | 'error'>;

const Select = ({
  items, value, onChange, sx, labelName = 'label', valueName = 'value', readOnly, label, onItemClick, error, ...props
}: SelectCustomProps) => (
  <FormControl fullWidth={props.fullWidth} component={Stack} spacing={0.5} error={!!error}>
    <Typography variant="body14rg" sx={{ color: 'base.500' }}>{label}</Typography>
    <MuiSelect
      value={value}
      size="small"
      IconComponent={KeyboardArrowDown}
      onChange={onChange}
      sx={sx}
      MenuProps={{
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center',
        },
        transformOrigin: {
          vertical: 0,
          horizontal: 'center',
        },
      }}
      {...props}
    >
      {
        items.map((item: any) => (
          <MenuItem
            onClick={() => onItemClick?.(item)}
            textColor={props.textColor}
            value={item[valueName || 'value']}
          >
            {item[labelName || 'label']}
          </MenuItem>
        ))
      }
    </MuiSelect>
  </FormControl>
);

export default Select;
