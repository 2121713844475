import React, { useCallback, useEffect } from 'react';
import { debounce } from 'lodash';
import { UseFormReturn } from 'react-hook-form';
import { AutocompleteInputChangeReason, FilterOptionsState } from '@mui/material';
import AutocompleteControl from 'components/form/AutocompleteControl';
import TextFieldControl from 'components/form/TextFieldControl';
import { GRADE_OPTIONS } from 'constants/global';
import { CompanyCandidatesQuery, useCompanyCandidatesQuery } from '@generated/graphql';
import { InfoInputs, Candidate } from './types';

type CandidateInfoProps = {
  form: UseFormReturn<InfoInputs>;
  isEditing: boolean;
};

const CandidateInfo: React.FC<CandidateInfoProps> = ({ form, isEditing }) => {
  const { loading: candidatesLoading, data: candidatesData, refetch } = useCompanyCandidatesQuery({
    variables: { input: {}, page: 0, limit: 15 },
  });

  const debounceQuery = useCallback(
    debounce((value, fieldName) => {
      refetch({ input: { [fieldName]: value }, limit: 15, page: 0 });
    }, 500),
    [],
  );

  const resetCandidateFields = () => {
    ['candidate.email', 'candidate.phoneNumber']
      .forEach((fieldName) => form.resetField(fieldName as keyof InfoInputs, { defaultValue: undefined }));
    form.resetField('candidate.grade', { defaultValue: { id: null, displayName: '' } });
  };

  const onFullNameInputChange = (
    inputValue: string,
    reason: AutocompleteInputChangeReason,
    filterName: 'fullName' | 'telegramUsername',
  ) => {
    const searchValue = inputValue.replace('@', '');
    debounceQuery(searchValue, filterName);

    if (reason === 'clear') {
      resetCandidateFields();
      form.resetField(`candidate.${filterName === 'fullName' ? 'selectedTelegramUser' : 'selectedCandidate'}`, {
        defaultValue: {
          id: undefined,
          fullName: undefined,
          candidateUsername: { telegramUsername: undefined },
          phoneNumber: undefined,
          grade: undefined,
          email: undefined,
        },
      });
    }
  };

  const setCandidateData = (data: Candidate) => {
    const values = [
      { name: 'candidate.grade', value: GRADE_OPTIONS?.find((grade) => data?.grade === grade.id) },
      { name: 'candidate.email', value: data?.email },
      { name: 'candidate.phoneNumber', value: data?.phoneNumber },
    ];
    values.forEach(({ name, value }) => form.setValue(name as keyof InfoInputs, value));
  };

  const selectedCandidateWatch = form.watch('candidate.selectedCandidate');
  useEffect(() => {
    if (selectedCandidateWatch?.id) {
      setCandidateData(selectedCandidateWatch);
      form.setValue(
        'candidate.selectedTelegramUser',
        selectedCandidateWatch,
      );
    }
  }, [selectedCandidateWatch?.id]);

  const selectedTelegramUsername = form.watch('candidate.selectedTelegramUser');
  useEffect(() => {
    if (selectedTelegramUsername?.id) {
      setCandidateData(selectedTelegramUsername);
      form.setValue('candidate.selectedCandidate', selectedTelegramUsername);
    }
  }, [selectedTelegramUsername?.id]);

  return (
    <>
      <AutocompleteControl
        name="candidate.selectedCandidate"
        label="ФИО"
        rules={{ required: true }}
        disabled={!isEditing}
        options={candidatesData?.companyCandidates.data || []}
        loading={candidatesLoading}
        onInputChange={(event, value, reason) => onFullNameInputChange(value, reason, 'fullName')}
        optionName="fullName"
        freeSolo
      />
      <AutocompleteControl
        name="candidate.selectedTelegramUser"
        label="Профиль Telegram"
        rules={{ required: true }}
        disabled={!isEditing}
        options={candidatesData?.companyCandidates.data || []}
        loading={candidatesLoading}
        optionName="candidateUsername.telegramUsername"
        freeSolo
        onInputChange={(event, value, reason) => onFullNameInputChange(value, reason, 'telegramUsername')}
        onChangeTextField={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
          const newEvent = event as React.ChangeEvent<HTMLInputElement>;
          const inputValue = event.target.value;
          newEvent.target.value = inputValue?.length > 1 || inputValue === '@'
            ? inputValue.replace(/(?!^)@/g, '')
            : `@${inputValue}`;
          return newEvent;
        }}
        filterOptionsParams={(params: FilterOptionsState<any>) => {
          const newParams = params;
          newParams.inputValue = params.inputValue.replace('@', '');
          return newParams;
        }}
        formatDataBeforeOnChange={(data: NonNullable<CompanyCandidatesQuery['companyCandidates']['data']>[number]) => {
          if (data) {
            const newData = JSON.parse(JSON.stringify(data));
            const telegramUsername = data.candidateUsername?.telegramUsername;
            const firstSymbol = telegramUsername?.[0];
            newData.candidateUsername.telegramUsername = firstSymbol === '@'
              ? telegramUsername
              : `@${telegramUsername}`;
            return newData;
          } return null;
        }}
      />
      <TextFieldControl
        name="candidate.phoneNumber"
        label="Номер телефона"
        disabled
        maskProps={{ mask: '+{7} (000) 000-00-00' }}
      />
      <TextFieldControl name="candidate.email" label="Почта" disabled={!isEditing} />
      <AutocompleteControl
        options={GRADE_OPTIONS}
        name="candidate.grade"
        label="Грейд кандидата"
        disabled={!isEditing}
      />
    </>
  );
};

export default CandidateInfo;
