import React, {
  Dispatch, SetStateAction, useContext,
} from 'react';
import { Card, Stack } from '@mui/material';
import { UseFormReturn } from 'react-hook-form';
import TextFieldControl from 'components/form/TextFieldControl';
import { CompetencyMapInputs } from 'pages/CompetencyMaps/view';
import SaveEditButtons from 'components/buttons/SaveEditButtons';
import { useCreateOrUpdateCompetencyMapMutation, useDeleteCompetencyMapMutation } from '@generated/graphql';
import { useNavigate, useParams } from 'react-router-dom';
import { ToastContext, ToastTypeEnum } from 'context/toastContext';
import useIsCreate from 'hooks/useIsCreate';
import CheckboxControl from 'components/form/CheckboxControl';
import ROUTES from 'constants/routes';
import { CompanyContext } from 'context/companyContext';
import { ConfirmModalContext } from 'context/confirmModalContext';

type InfoProps = {
  form: UseFormReturn<CompetencyMapInputs>;
  isEdited: boolean;
  setIsEdited: Dispatch<SetStateAction<boolean>>
};

const Info = ({ form, isEdited, setIsEdited }: InfoProps) => {
  const navigate = useNavigate();
  const isCreate = useIsCreate();

  const { addToast } = useContext(ToastContext);
  const { handleOpen } = useContext(ConfirmModalContext);
  const { currentCompanyId } = useContext(CompanyContext);
  const { mapId } = useParams();

  const { handleSubmit, formState: { errors } } = form;
  const [updateMap, { loading }] = useCreateOrUpdateCompetencyMapMutation();

  const onSubmit = (formData: CompetencyMapInputs) => {
    setIsEdited(false);
    updateMap({
      variables: {
        input: {
          companyId: currentCompanyId!,
          title: formData.title!,
          id: mapId,
          specialization: formData.specialization!,
          comment: formData.comment,
          useInBot: formData.useInBot,
          demoCode: formData.demoCode,
          blocks: formData.knowledgeBlocks!.map((block) => ({
            id: block.id,
            title: block.title!,
            competencesIds: block.knowledgeBlockCompetences!
              .map((competence) => competence.id) as string[],
          })),
          isRepositoryCheck: formData.isRepositoryCheck,
        },
      },
    }).then((res) => {
      if (isCreate) navigate(`/${ROUTES.COMPETENCE_MAPS}/view/${res.data?.createOrUpdateCompetencyMap.id}`);
      setIsEdited(false);
      addToast({ type: ToastTypeEnum.SUCCESS });
    }).catch(() => addToast({ type: ToastTypeEnum.ERROR }));
  };

  const onError = () => {
    const firstError = Object.values(errors)?.[0];
    const errorText = firstError?.message || firstError?.root?.message;
    addToast({ type: ToastTypeEnum.ERROR, text: errorText as string | undefined });
  };

  const [deleteMap] = useDeleteCompetencyMapMutation();

  const handleDeleteMap = async () => {
    await deleteMap({
      variables: {
        competencyMapId: mapId!,
      },
    }).then(() => {
      addToast({ type: ToastTypeEnum.SUCCESS, text: 'Карта компетенций удалена' });
      navigate(`/${ROUTES.COMPETENCE_MAPS}`);
    }).catch(() => addToast({ type: ToastTypeEnum.ERROR }));
  };

  const handleOpenConfirmDeleteMapModal = () => {
    handleOpen({
      title: 'Вы уверены, что хотите удалить карту кометенций',
      actionButtonText: 'Удалить',
      handleClick: handleDeleteMap,
    });
  };

  return (
    <Card>
      <Stack spacing={2} mb={4}>
        <TextFieldControl label="Название" name="title" disabled={!isEdited} rules={{ required: true }} />
        <TextFieldControl label="Специализация" name="specialization" disabled={!isEdited} rules={{ required: true }} />
        <TextFieldControl label="Комментарий" name="comment" multiline minRows={4} maxRows={4} disabled={!isEdited} />
        <TextFieldControl
          label="Код карты"
          name="demoCode"
          disabled={!isEdited}
          maskProps={{ mask: '000000000' }}
          rules={{
            minLength: { value: 4, message: 'Минимум 4 цифры' },
            maxLength: { value: 9, message: 'Максимум 9 цифр' },
          }}
        />
        <TextFieldControl name="grades" label="Грейд" disabled />
        <CheckboxControl label="отображать в боте" name="useInBot" disabled={!isEdited} />
        <CheckboxControl label="запрос проверки репозитория" name="isRepositoryCheck" disabled={!isEdited} />
        <TextFieldControl name="createdAt" label="Дата создания" disabled />
        <TextFieldControl name="updatedAt" label="Дата изменения" disabled />
      </Stack>
      <SaveEditButtons
        deleteText="Удалить карту"
        onDelete={handleOpenConfirmDeleteMapModal}
        isEdited={isEdited}
        onSubmit={handleSubmit(onSubmit, onError)}
        setIsEdited={setIsEdited}
        loading={loading}
      />
    </Card>
  );
};

export default Info;
