import React, { FC } from 'react';
import { UseFormWatch } from 'react-hook-form';
import { checkIsDateValid } from 'helpers/date';
import DatePickerControl from 'components/form/DatePickerControl';
import TextFieldControl from 'components/form/TextFieldControl';
import AutocompleteControl from 'components/form/AutocompleteControl';

export enum InputTypes {
  DATE = 'DATE',
  TEXT = 'TEXT',
  AUTOCOMPLETE = 'AUTOCOMPLETE',
}

export const INPUT_DEFAULT_VALUE = {
  [InputTypes.DATE]: null,
  [InputTypes.TEXT]: '',
  [InputTypes.AUTOCOMPLETE]: null,
};
type InputsGeneratorProps = {
  input: any;
  watch: UseFormWatch<any>;
};

const GeneratedInput: FC<InputsGeneratorProps> = ({ input, watch, ...rest }) => {
  const { label, name, inputType } = input;
  switch (inputType) {
    case InputTypes.DATE: {
      return (
        <DatePickerControl
          rules={{ validate: (value: Date) => (value ? checkIsDateValid(value) : true) }}
          label={label}
          name={name}
          {...{ ...input, ...rest }}
        />
      );
    }
    case InputTypes.TEXT: {
      return (
        <TextFieldControl
          name={name}
          label={label}
          type={input.type}
          size="small"
          {...{ ...input, ...rest }}
        />
      );
    }
    case InputTypes.AUTOCOMPLETE: {
      return (
        <AutocompleteControl
          name={name}
          label={label}
          options={input.options}
          onInputChange={input.onInputChange}
          getOptionLabel={input.getOptionLabel}
          defaultValue={input.defaultValue || input.multiple ? [] : null}
          {...{ ...input, ...rest }}
        />
      );
    }

    default:
      return null;
  }
};

export default GeneratedInput;
