import React, {
  useContext, useEffect, useRef, useState,
} from 'react';
import {
  Card, Grid, Stack, Typography, Button,
} from '@mui/material';
import KnowledgeBlock from 'pages/CompetencyMaps/view/components/KnowledgeBlock';
import { useFieldArray, useForm } from 'react-hook-form';
import { useParams, Link } from 'react-router-dom';

import CircularLoading from 'components/CircularLoading';
import EmptyList from 'components/EmptyList';
import Form from 'components/form/Form';

import { useCompetencyMapQuery } from '@generated/graphql';
import { ConfirmModalContext } from 'context/confirmModalContext';
import useIsCreate from 'hooks/useIsCreate';
import useSetTabTitle from 'hooks/useSetTabTitle';
import useCreateTesting from 'hooks/useCreateTesting';
import { formatISODate } from 'helpers/date';

import PlusIcon from 'assets/icons/PlusIcon';
import ROUTES from 'constants/routes';
import { CompanyContext } from 'context/companyContext';
import ButtonWithPermissions, { generateUnavailableText } from 'components/buttons/ButtonWithPermissions';
import Info from './components/Info';
import CopyMapModal from './components/CopyMapModal';

export type CompetencyMapInputs = {
  id?: string,
  title?: string,
  specialization?: string,
  comment?: string | null,
  createdAt?: any,
  updatedAt?: any,
  grades?: string;
  useInBot: boolean;
  isRepositoryCheck: boolean;
  demoCode: string;
  knowledgeBlocks?: Array<{
    id?: string,
    title?: string,
    knowledgeBlockCompetences?: Array<{
      id?: string,
      title?: string,
      topics?: Array<{ id: string, title: string }> | null
    }> | null
  }> | null
};

const CompetencyMapView = () => {
  const isCreate = useIsCreate();
  const [isEdited, setIsEdited] = useState<boolean>(isCreate);
  const { handleOpen } = useContext(ConfirmModalContext);
  const blockContainerRef = useRef<HTMLDivElement>(null);
  const { isCreateAvailable, currentCompany } = useContext(CompanyContext);

  const createAssessmentUnavailableText = generateUnavailableText(
    isCreateAvailable,
    currentCompany,
    'создания тестирования',
  );

  const { mapId } = useParams();
  const { data, loading } = useCompetencyMapQuery({
    variables: { competencyMapId: mapId! },
    skip: !mapId,
  });

  const competencyMap = data?.competencyMap;
  const form = useForm<CompetencyMapInputs>({
    values: {
      id: competencyMap?.id,
      title: competencyMap?.title,
      comment: competencyMap?.comment,
      specialization: competencyMap?.specialization,
      grades: competencyMap?.grades?.join(', '),
      demoCode: competencyMap?.demoCode!,
      updatedAt: competencyMap?.updatedAt ? formatISODate(competencyMap.updatedAt, 'dd.MM.yyyy HH:mm') : '',
      createdAt: competencyMap?.createdAt ? formatISODate(competencyMap.createdAt, 'dd.MM.yyyy HH:mm') : '',
      useInBot: competencyMap?.useInBot || false,
      isRepositoryCheck: competencyMap?.isRepositoryCheck || false,
      knowledgeBlocks: competencyMap?.knowledgeBlocks?.map((block) => ({
        id: block.id,
        title: block.title,
        knowledgeBlockCompetences: block.knowledgeBlockCompetences?.map((competence) => ({
          id: competence.competence.id,
          title: competence.competence.title,
          topics: competence.competence.topics,
        })),
      })),
    },
  });

  const { control } = form;
  const { fields, remove, append } = useFieldArray({
    control,
    name: 'knowledgeBlocks',
    keyName: 'formId',
  });

  const handleRemoveBlock = (index: number) => {
    handleOpen({
      title: 'Вы уверены, что хотите удалить блок знаний?',
      handleClick: () => remove(index),
    });
  };

  const { createTesting } = useCreateTesting({ competencyMap });

  const title = `Карта компетенций ${competencyMap?.title ? ` «${competencyMap.title}»` : ''}`;
  useSetTabTitle(title, competencyMap?.title);

  const [openCopyModal, setOpenCopyModal] = useState<boolean>(false);

  const handleCopyMap = () => {
    setOpenCopyModal(true);
  };

  const [scrollWidth, setScrollWidth] = useState<number>(0);

  useEffect(() => {
    if (blockContainerRef.current) {
      const currentScrollWidth = blockContainerRef.current.scrollWidth;
      if (currentScrollWidth > scrollWidth && scrollWidth !== 0) {
        blockContainerRef.current.scrollTo({
          left: blockContainerRef.current.scrollWidth,
          behavior: 'smooth',
        });
      }
      setScrollWidth(blockContainerRef.current.scrollWidth);
    }
  }, [blockContainerRef.current?.scrollWidth, fields.length]);

  const handleAddBlock = () => {
    append({ title: '', knowledgeBlockCompetences: undefined });
  };

  const originalCompetencyMap = competencyMap?.originalCompetencyMap;

  if (loading) return <CircularLoading />;

  return (
    <>
      <Stack direction="row" justifyContent="space-between" mb={2.5}>
        <Typography variant="h28">{title}</Typography>
        <Stack direction="row" spacing={2}>
          <ButtonWithPermissions
            buttonText="Добавить блок знаний"
            sx={{ backgroundColor: 'white', color: 'text.primary', '&:hover': { color: 'white' } }}
            onClick={handleAddBlock}
            disabled={!isEdited}
          />
          {!isCreate && (
            <ButtonWithPermissions
              buttonText="Создать тестирование"
              onClick={createTesting}
              tooltipText={createAssessmentUnavailableText}
            />
          )}
        </Stack>
      </Stack>
      <Form form={form}>
        <Grid container columnSpacing={2.5}>
          <Grid item xs={12} md={9}>
            <Stack direction="row" sx={{ overflowX: 'auto', height: '100%' }} spacing={2.5} ref={blockContainerRef}>
              {!fields.length && !isEdited ? (
                <EmptyList emptyPlaceholderText="Пока нет блоков знаний" />
              ) : (
                fields.map((field, index) => (
                  <KnowledgeBlock<CompetencyMapInputs>
                    key={field.formId}
                    onRemoveBlock={() => handleRemoveBlock(index)}
                    name={`knowledgeBlocks.${index}`}
                    isEdited={isEdited}
                    form={form}
                  />
                ))
              )}
            </Stack>
          </Grid>
          <Grid item xs={12} md={3}>
            <Stack spacing={2.5}>
              <Info form={form} isEdited={isEdited} setIsEdited={setIsEdited} />
              <Button
                startIcon={<PlusIcon sx={{ width: 16, height: 16 }} />}
                sx={({ typography }) => ({
                  px: 0, justifyContent: 'flex-end', color: 'base.200', ...typography.body14rg,
                })}
                color="primary"
                onClick={handleCopyMap}
              >
                дублировать карту
              </Button>
              {originalCompetencyMap && (
                <Card>
                  <Typography variant="body16rg">
                    Является дубликатом карты
                    {' '}
                    <Link
                      to={`/${ROUTES.COMPETENCE_MAPS}/view/${originalCompetencyMap.id}`}
                    >
                      {originalCompetencyMap.title}
                    </Link>
                  </Typography>
                </Card>
              )}
            </Stack>
          </Grid>
        </Grid>
      </Form>
      {openCopyModal && competencyMap && (
        <CopyMapModal
          open={openCopyModal}
          onClose={() => setOpenCopyModal(false)}
          originalMap={{ id: competencyMap.id, title: competencyMap.title }}
        />
      )}
    </>
  );
};

export default CompetencyMapView;
