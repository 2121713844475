import React, {
  useContext, useEffect, useMemo, useState,
} from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import useIsCreate from 'hooks/useIsCreate';
import CircularLoading from 'components/CircularLoading';
import {
  Trial_Status,
  useCompetencyMapOptionsQuery, useCreateOrUpdateCompanyCandidateMutation,
  useCreateOrUpdateTrialMutation,
  useTrialInfoQuery,
} from '@generated/graphql';
import Box from '@mui/material/Box';
import SaveEditButtons from 'components/buttons/SaveEditButtons';
import { useForm } from 'react-hook-form';
import { ToastContext, ToastTypeEnum } from 'context/toastContext';
import {
  Grid, Stack, Typography, Link,
} from '@mui/material';
import { formatISODate } from 'helpers/date';
import TestingStatus from 'components/TestingStatus';
import Form from 'components/form/Form';
import TextFieldControl from 'components/form/TextFieldControl';
import AutocompleteControl from 'components/form/AutocompleteControl';
import qs from 'qs';
import CopyButton from 'components/buttons/CopyButton';
import CandidateInfo from 'pages/Testing/view/components/InfoTab/CandidateInfo';
import { GRADE_OPTIONS } from 'constants/global';
import ROUTES from 'constants/routes';
import { CompanyContext } from 'context/companyContext';
import { InfoInputs } from './types';

const InfoTab = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const params = useMemo(() => qs.parse(searchParams.toString()), [searchParams]);
  const { mapId } = params as unknown as { mapId?: string; };
  const { addToast } = useContext(ToastContext);
  const { currentCompanyId } = useContext(CompanyContext);
  const { trialId } = useParams();
  const isCreate = useIsCreate();
  const [isEditing, setIsEditing] = useState<boolean>(isCreate);

  const { data, loading } = useTrialInfoQuery({
    variables: {
      trialId: trialId!,
    },
    skip: !trialId,
  });

  const {
    data: competencyMapsData,
    loading: competencyMapsLoading,
  } = useCompetencyMapOptionsQuery({
    fetchPolicy: 'cache-and-network',
  });

  const trial = data?.trial;

  const competencyMap = mapId
    ? competencyMapsData?.competencyMaps.data?.find((map) => map.id === mapId)
    : trial?.competencyMap;

  const startedAt = trial?.trialStatusHistory
    ?.find((history) => history.newStatus === Trial_Status.Started)?.createdAt;

  const form = useForm<InfoInputs>({
    values: {
      id: trialId || '',
      candidate: {
        selectedCandidate: trial?.companyCandidate ? {
          id: trial?.companyCandidate.id || '',
          fullName: trial?.companyCandidate.fullName || '',
          grade: trial?.companyCandidate.grade,
          candidateUsername: {
            telegramUsername: trial?.companyCandidate.candidateUsername.telegramUsername
              ? `@${trial?.companyCandidate.candidateUsername.telegramUsername}`
              : '',
          },
          phoneNumber: trial?.companyCandidate.candidateUsername.candidate?.phoneNumber,
          email: trial?.companyCandidate.email,
        } : undefined,
        selectedTelegramUser: trial?.companyCandidate ? {
          id: trial?.companyCandidate.id || '',
          fullName: trial?.companyCandidate.fullName || '',
          grade: trial?.companyCandidate.grade,
          candidateUsername: {
            telegramUsername: trial?.companyCandidate.candidateUsername.telegramUsername
              ? `@${trial?.companyCandidate.candidateUsername.telegramUsername}`
              : '',
          },
          phoneNumber: trial?.companyCandidate.candidateUsername.candidate?.phoneNumber!,
          email: trial?.companyCandidate.email,
        } : undefined,
        grade: GRADE_OPTIONS?.find((grade) => trial?.companyCandidate.grade === grade.id),
        phoneNumber: trial?.companyCandidate.candidateUsername.candidate?.phoneNumber!,
        email: trial?.companyCandidate.email,
      },
      comment: trial?.comment,
      createdAt: trial?.createdAt ? formatISODate(trial?.createdAt, 'dd.MM.yyyy HH:mm') : '',
      completedAt: trial?.completedAt ? formatISODate(trial?.completedAt, 'dd.MM.yyyy HH:mm') : '',
      startedAt: startedAt ? formatISODate(startedAt, 'dd.MM.yyyy HH:mm') : '',
      competencyMap: competencyMap ? {
        selectedMap: {
          id: competencyMap?.id || '',
          title: competencyMap?.title || '',
          comment: competencyMap?.comment,
          grades: competencyMap?.grades,
        },
        comment: competencyMap?.comment,
        grades: competencyMap?.grades?.join(', '),
      } : undefined,
      link: trial?.id ? `https://t.me/${process.env.REACT_APP_BOT_URL}?start=${trial.id}` : '',
    },
  });
  const { handleSubmit } = form;

  const [updateTrial, { loading: updateTrialLoading }] = useCreateOrUpdateTrialMutation();
  const [updateCompanyCandidate,
    { loading: updateCompanyCandidateLoading }] = useCreateOrUpdateCompanyCandidateMutation();

  const onSubmit = (formData: InfoInputs) => {
    updateCompanyCandidate({
      variables: {
        input: {
          id: formData.candidate.selectedCandidate?.id!,
          email: formData.candidate?.email,
          companyId: currentCompanyId!,
          fullName: formData.candidate.selectedCandidate?.fullName!,
          grade: formData.candidate.grade?.id!,
          telegramUsername:
            formData.candidate.selectedTelegramUser?.candidateUsername?.telegramUsername!.replace('@', '')!,
        },
      },
    })
      .then((candidateRes) => {
        const candidateId = candidateRes.data?.createOrUpdateCompanyCandidate.id;
        if (candidateId) {
          updateTrial({
            variables: {
              input: {
                id: trialId,
                competencyMapId: formData.competencyMap?.selectedMap.id!,
                comment: formData.comment,
                companyCandidateId: candidateId,
              },
            },
          }).then((res) => {
            if (isCreate) navigate(`/${ROUTES.TESTING}/view/${res.data?.createOrUpdateTrial.id}`);
            setIsEditing(false);
            addToast({ type: ToastTypeEnum.SUCCESS });
          })
            .catch(() => addToast({ type: ToastTypeEnum.ERROR }));
        } else {
          addToast({ type: ToastTypeEnum.ERROR, text: 'Ошибка сохранения кандидата' });
        }
      })
      .catch(() => addToast({ type: ToastTypeEnum.ERROR, text: 'Ошибка сохранения кандидата' }));
  };

  const link = form.watch('link');
  const competencyMapWatch = form.watch('competencyMap');

  useEffect(() => {
    if (competencyMapWatch?.selectedMap?.id) {
      form.setValue('competencyMap.comment', competencyMapWatch?.selectedMap?.comment);
      form.setValue('competencyMap.grades', competencyMapWatch?.selectedMap?.grades?.join(', '));
    }
  }, [competencyMapWatch?.selectedMap?.id]);

  if (loading || competencyMapsLoading) return <CircularLoading />;

  return (
    <Box sx={{ py: 2 }}>
      <Form form={form}>
        <Grid container rowSpacing={2.5}>
          <Grid container item xs={12} spacing={2.5}>
            <Grid item xs={12} md={6}>
              <Stack spacing={2}>
                <CandidateInfo
                  form={form}
                  isEditing={isEditing}
                />
                <TextFieldControl
                  name="comment"
                  label="Комментарий к тестированию"
                  minRows={6}
                  disabled={!isEditing}
                  multiline
                />
              </Stack>

            </Grid>
            <Grid item xs={12} md={6}>
              <Stack spacing={2}>
                <AutocompleteControl
                  options={competencyMapsData?.competencyMaps.data}
                  name="competencyMap.selectedMap"
                  optionName="title"
                  label="Карта компетенций"
                  disabled={!isEditing}
                  loading={competencyMapsLoading}
                  rules={{ required: true }}
                />
                <TextFieldControl
                  name="competencyMap.comment"
                  label="Комментарий к карте компетенций"
                  minRows={7}
                  multiline
                  disabled
                />
                <TextFieldControl name="competencyMap.grades" label="Грейд карты компетенций" disabled />
                <TextFieldControl name="createdAt" label="Дата создания тестирования" disabled />
                {!isCreate && (
                  <Stack spacing={0.5}>
                    <Typography variant="body14rg" sx={{ color: 'base.500' }}>
                      Ссылка на тестирование
                    </Typography>
                    <Box sx={{
                      backgroundColor: 'bgSwitch', px: 1.5, py: 1.3, borderRadius: 2,
                    }}
                    >
                      <CopyButton copyText={link!}>
                        <Link variant="body16rg" href={link} sx={{ textDecoration: 'none' }} target="_blank">
                          {link}
                        </Link>
                      </CopyButton>

                    </Box>
                  </Stack>
                )}
                <TextFieldControl name="startedAt" label="Начало тестирования" disabled />
                <TextFieldControl name="completedAt" label="Завершение тестирования" disabled />
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <SaveEditButtons
                isEdited={isEditing}
                onSubmit={handleSubmit(onSubmit)}
                setIsEdited={setIsEditing}
                loading={updateTrialLoading || updateCompanyCandidateLoading}
              />
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Stack spacing={2}>
              {
                trial?.trialStatusHistory?.map((status) => (
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="end"
                    spacing={2}
                    sx={{
                      width: '100%', backgroundColor: 'bgSwitch', px: 2, py: 1.5,
                    }}
                  >
                    <Typography variant="body16rg">
                      {formatISODate(status.createdAt, 'dd.MM.yyyy HH:mm')}
                    </Typography>
                    <TestingStatus status={status.newStatus} />
                  </Stack>
                ))
              }
            </Stack>

          </Grid>
        </Grid>
      </Form>
    </Box>
  );
};

export default InfoTab;
